define("additive-mc/routes/instance", ["exports", "additive-mc/config/environment", "@ember/routing/route", "@ember/service", "ember-concurrency", "additive-mc/utils/constants", "@ember/object", "@additive-apps/auth/mixins/auth-route-mixin", "@userback/widget"], function (_exports, _environment, _route, _service, _emberConcurrency, _constants, _object, _authRouteMixin, _widget) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let InstanceRoute = _exports.default = (_dec = (0, _emberConcurrency.task)(function* () {
    try {
      var _this$currentUser, _this$currentUser2, _this$currentUser3;
      const organization = this.currentUser.currentOrganization;
      let tasks = [];
      tasks.push(this.currentUser.loadUser());
      tasks.push(this.uiAppSettings.loadLanguages());
      yield (0, _emberConcurrency.all)(tasks);
      yield this.uiLocale.setLocale(((_this$currentUser = this.currentUser) === null || _this$currentUser === void 0 || (_this$currentUser = _this$currentUser.user) === null || _this$currentUser === void 0 ? void 0 : _this$currentUser.language) || ((_this$currentUser2 = this.currentUser) === null || _this$currentUser2 === void 0 || (_this$currentUser2 = _this$currentUser2.currentOrganization) === null || _this$currentUser2 === void 0 ? void 0 : _this$currentUser2.language) || 'de');
      if (this.currentUser.isAdditiveUser) {
        const widgetKey = _environment.default.APP.productlaneWidgetKey;

        // Ensure the script is loaded if not already
        if (!document.getElementById('productlane-script')) {
          const script = document.createElement('script');
          script.id = 'productlane-script';
          script.async = true;
          script.defer = true;
          script.crossOrigin = 'anonymous';
          script.src = 'https://widget.productlane.com/latest.productlane-widget.min.js';
          document.body.appendChild(script);
        }
        (w => {
          const P = w.Productlane = {
            queue: {}
          };
          ['set', 'open', 'close', 'toggle', 'on', 'off', 'init'].forEach(m => {
            P[m] = (n => function () {
              P.queue[n] = {
                args: arguments
              };
            })(m);
          });
        })(window);
        window.Productlane.init({
          widgetKey,
          theme: {
            '--text-color-primary-dark': '#00172F',
            '--text-color-secondary-dark': '#8F9EA4',
            '--text-color-highlight-dark': '#4d76f1',
            '--background-color-primary-dark': '#ffffff',
            '--background-color-secondary-dark': '#8f9ea41a',
            '--border-color-primary-dark': '#061C2D1A',
            '--text-color-primary': '#00172F',
            '--text-color-secondary': '#8F9EA4',
            '--text-color-highlight': '#4d76f1',
            '--background-color-primary': '#ffffff',
            '--background-color-secondary': '#8f9ea41a',
            '--border-color-primary': '#061C2D1A'
          },
          user: {
            email: this.currentUser.organizationUser.email
          }
        });
        window.Productlane.on('loaded', () => {
          var _document$getElementB;
          const widget = (_document$getElementB = document.getElementById('ProductlaneWidget-MainWidget')) === null || _document$getElementB === void 0 ? void 0 : _document$getElementB.parentElement;
          widget.style.zIndex = '9999999999';
          const hasPermanentNavdrawer = document.querySelector('.ui-navigation-drawer--permanent');
          if (hasPermanentNavdrawer) {
            widget.style.left = '284px';
          } else {
            widget.style.left = '0px';
          }
        });
      }
      if (this.currentUser.isAdditiveUser || this.currentUser.isPartnerUser) {
        (0, _widget.default)(this.currentUser.isAdditiveUser ? _environment.default.APP.userbackAccessToken : _environment.default.APP.userbackAccessTokenPartner, {
          email: this.currentUser.user.email,
          name: this.currentUser.user.fullName,
          categories: 'MA'
        });
      }
      if (typeof Sprig === 'function' && (_this$currentUser3 = this.currentUser) !== null && _this$currentUser3 !== void 0 && _this$currentUser3.user) {
        /* eslint-disable */
        Sprig('setUserId', this.currentUser.user.id);
        Sprig('setEmail', this.currentUser.user.email);
        /* eslint-enable */
      }
      const isManaged = this.uiAppSettings.managed;
      let isManagedReadOnly = false;
      if (isManaged) {
        if (this.uiAppSettings.managedByPartner) {
          isManagedReadOnly = !this.currentUser.isPartnerUser && !this.currentUser.isAdditiveUser;
        } else {
          isManagedReadOnly = !this.currentUser.isAdditiveUser;
        }
      }
      this.currentUser.isReadOnly = this.currentUser.isViewer || isManagedReadOnly;
      return organization;
    } catch (e) {
      this.uiToast.showToast({
        title: this.intl.t('toast.unexpectedError'),
        type: 'error'
      });
    }
  }), _class = class InstanceRoute extends _route.default.extend(_authRouteMixin.default) {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "authenticatedFetch", _descriptor, this);
      _initializerDefineProperty(this, "currentUser", _descriptor2, this);
      _initializerDefineProperty(this, "session", _descriptor3, this);
      _initializerDefineProperty(this, "store", _descriptor4, this);
      _initializerDefineProperty(this, "uiAppSettings", _descriptor5, this);
      _initializerDefineProperty(this, "intl", _descriptor6, this);
      _initializerDefineProperty(this, "uiToast", _descriptor7, this);
      _initializerDefineProperty(this, "uiLocale", _descriptor8, this);
      _initializerDefineProperty(this, "fetchOrganizationData", _descriptor9, this);
    }
    async model(params) {
      const organization = await this.store.findRecord('organization', params.instance_id);
      this.currentUser.currentOrganization = organization;
      return organization;
    }
    async setupController() {
      super.setupController(...arguments);
      await this.fetchOrganizationData.perform();
      if (!localStorage.getItem(_constants.LOCAL_STORAGE_FILTER_KEY)) localStorage.setItem(_constants.LOCAL_STORAGE_FILTER_KEY, JSON.stringify({}));
      const localStorageDefault = JSON.parse(localStorage.getItem(_constants.LOCAL_STORAGE_FILTER_KEY));

      // get date filter from query params
      const urlParams = new URLSearchParams(window.location.search);
      const dateFilter = urlParams.get('dateFilter');
      const start = urlParams.get('start');
      const end = urlParams.get('end');

      // set local storage entry to query param
      if (dateFilter && !start && !end) localStorageDefault.dateFilter = dateFilter;else if (end && start) {
        localStorageDefault.start = start;
        localStorageDefault.end = end;
      }
      if ((!localStorageDefault.start || !localStorageDefault.end) && !localStorageDefault.dateFilter) {
        localStorageDefault.dateFilter = 'last_month';
      }
      localStorage.setItem(_constants.LOCAL_STORAGE_FILTER_KEY, JSON.stringify(localStorageDefault));
    }
    loading() {
      return !this.currentUser.currentOrganization;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "authenticatedFetch", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "session", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "uiAppSettings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "uiToast", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "uiLocale", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "fetchOrganizationData", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "loading", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "loading"), _class.prototype), _class);
});